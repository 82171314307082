<template>
  <div>
    <!-- {{params['pcr_checklist_with_user'][0]['id']}} -->
    <div>
      <b-form-group :label="title" :label-class="!title ? 'd-none' : ''" :label-cols="12" :label-cols-sm="3">
        <div v-if="title" slot="label">
          <b>{{ title }}</b>
        </div>
        <div>
          <b-row v-if="rel_info">
            <b-col cols="12" v-if="rel_info">
              <div class="alert alert-info" role="alert">{{ rel_info }}</div>
            </b-col>
          </b-row>
          <div v-if="!view_only">
            <b-collapse class="mt-2" v-model="show_selecting_search" :id="$d.randStr()">
              <SelectingSearch
                v-if="show_selecting_search"
                ref="selectingSearch"
                :_search_component="_search_component"
                :relationship="relationship"
                :params.sync="params"
                :enable_map_default="false"
                :stateIdentifier="stateIdentifier"
                :_index_params="index_params"
                :_exclude_fields="_exclude_select_fields"
                :small="small"
                :except_virtual_property_keys="except_virtual_property_keys"
                @rowClicked="rowClicked"
                @rowHovered="rowHovered"
                @rowUnhovered="rowUnhovered"
              />
            </b-collapse>
            <b-button @click="show_selecting_search = !show_selecting_search" :class="show_selecting_search ? 'collapsed' : null" size="sm">{{
              show_selecting_search ? 'Hide Select' : ' Show Select'
            }}</b-button>
            <hr />
          </div>
          <PendingTable
            :relationship="relationship"
            :params.sync="params"
            :enableRemove="!view_only"
            :fixed_params="fixed_params"
            :_exclude_fields="_exclude_pending_fields"
            :small="small"
          ></PendingTable>
        </div>
      </b-form-group>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';

export default {
  components: {
    SelectingSearch: () => import('./SelectingSearch'),
    PendingTable: () => import('./PendingTable'),
  },
  props: {
    _search_component: {},
    small: {
      type: Boolean,
      default: true,
    },
    view_only: {
      type: Boolean,
      default: false,
    },
    fixed_params: {
      type: Object,
    },
    relationship: {
      type: Object,
    },
    params: {
      type: Object,
      required: true,
    },
    default_show_search: {
      type: Boolean,
      default: true,
    },
    _show_selecting_search: {
      type: Boolean,
    },
    except_virtual_property_keys: {},
    title: {
      type: String,
    },
    _index_params: {
      type: Object,
    },
    _exclude_select_fields: {
      type: Array,
      default: () => {
        return [];
      },
    },
    _exclude_pending_fields: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data: () => {
    return {
      show_selecting_search_: null, //backup variable, in case of attempt to save state failed
    };
  },
  computed: {
    index_params() {
      let index_params = {
        index_withs: true,
      };
      index_params = _.merge(index_params, this._index_params);
      return index_params;
    },
    stateIdentifier() {
      let stateIdentifier = '';
      if (this.params['id']) {
        // stateIdentifier = '_' + String(this.params['id']); //TODO: kinda unfair to set the stateIdentifier based on the items's id, kinda useless if browsing other ID's
      } else {
        // stateIdentifier = '_' + String(Math.random().toString(36).slice(2)); //random string
      }
      return stateIdentifier;
    },
    relation_alias() {
      return this.$d.getRelationAlias(this.relationship);
    },
    show_selecting_search: {
      // getter
      get: function() {
        if (!_.isNil(this.show_selecting_search_)) {
          return this.show_selecting_search_;
        } else if (!_.isNil(this._show_selecting_search)) {
          return this._show_selecting_search;
        } else if (this.default_show_search == true) {
          return true;
        }

        return false;
      },
      // setter
      set: function(newValue) {
        this.show_selecting_search_ = newValue; //internal variable, if external prop not defined
        this.$emit('update:_show_selecting_search', newValue);
      },
    },
    rel_info() {
      return _.get(this.relationship, ['to', 'frontend', 'info']);
    },
  },
  created() {},
  mounted() {},
  methods: {
    rowClicked(item) {
      this.$emit('rowClicked', item);
    },
    rowHovered(item) {
      this.$emit('rowHovered', item);
    },
    rowUnhovered(item) {
      this.$emit('rowUnhovered', item);
    },
  },
};
</script>
